import {
  InputAdornment,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { borderColor, secondaryColor } from "../../../scss/colors.scss";

const CssTextField = withStyles({
  root: {
    display: "flex",
    width: "100%",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: secondaryColor,
    },
    "& .MuiOutlinedInput-root": {
      alignItems: "stretch",
      "& fieldset": {
        top: 0,
        borderColor,
        "& legend": {
          display: "none",
        },
      },
      "&:hover fieldset": {
        borderColor: secondaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: secondaryColor,
      },
      "& input": {
        cursor: "text",
        flex: "1 1",
      },
    },
  },
})(TextField);

const CustomSharingInput = ({
  isDisabled,
  isActivated,
  isAPILoading,
  placeholder,
  handleChange,
  handleBlur,
  fieldName,
  value,
  error,
  touched,
  handleClickButton,
  handleClickInput = () => {},
  customInputElement,
  buttonText = "",
  inputRef,
}) => {
  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClickButton();
    }
  };
  const inputElement = customInputElement || (
    <CssTextField
      inputRef={inputRef}
      type="text"
      disabled={isDisabled || isAPILoading}
      name={fieldName}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
      onClick={() => {
        handleClickInput();
        setTimeout(() => {
          inputRef.current.focus();
        }, 300);
      }}
      onBlur={handleBlur}
      value={value}
      variant="outlined"
      id="custom-css-outlined-input"
      autoComplete="new-password"
      helperText={error && touched && error}
      error={error && touched}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            className={classNames("sharing-input-adornment__container", {
              "d-none": value.length === 0,
            })}
          >
            {isDisabled ? (
              <CopyToClipboard text={value} onCopy={handleClickButton}>
                <div className="sharing-input-adornment__button">
                  {buttonText}
                </div>
              </CopyToClipboard>
            ) : (
              <div
                className="sharing-input-adornment__button"
                onClick={handleClickButton}
                isAPILoading={isAPILoading}
              >
                {buttonText}
              </div>
            )}
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Paper
      className={classNames(`custom-input-container sign-up-form-field`, {
        activated: isActivated,
      })}
    >
      {inputElement}
    </Paper>
  );
};

CustomSharingInput.propTypes = {};

export default CustomSharingInput;
