const checkEmailVerification = (value) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
};

const checkPhoneVerification = (value) => {
  return !/^(0|\+61|\+84){0,1}((0){0,1}(2|4|3|7|8)){0,1}[0-9]{8,9}$/.test(
    value
  );
};
const convertPhoneNumberToStar = (value) => {
  return value.replace(/\d(?=(?:\D*\d){3})/g, "*");
};

export {
  checkEmailVerification,
  checkPhoneVerification,
  convertPhoneNumberToStar,
};
